<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-row>
      <v-col>
        <span
          style="cursor: pointer"
          @click="navBack"
        >
          <v-icon
            style="
            font-size: 16px;
            color: #7A1878;
          "
          >
            fas fa-chevron-left
          </v-icon>
          <span
            class="ml-1"
            style="color: #7A1878"
          >
            Kembali
          </span>
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col style="font-size: 26px">
        Detail Mitra
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col>
        <!-- Informasi Perusahaan -->
        <v-card class="px-4 pt-4 pb-8">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Merek
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.company_info.name || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Legal
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.company_info.name_legal || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Info Bank
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.company_info.bank_name || '-' }}
              <span v-if="detail.company_info.bank_verified">
                <v-icon color="green">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon color="red">
                  mdi-close-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.company_info.phone || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Alamat
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.company_info.address || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="font-common-black-bold">
              Pimpinan Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Pimpinan
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.board_info.name || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.board_info.phone || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Tambahan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Kontrak Hingga
            </v-col>

            <v-col
              v-if="toggleKontrakHingga === false"
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.addon_info.contract_validity_date || '-' }}
              <v-tooltip
                bottom
                color="purple"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-3"
                    outlined
                    x-small
                    fab
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleKontrakHingga = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Tanggal Kontrak</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="toggleKontrakHingga === true"
              class="font-common-bold"
              md="4"
              cols="4"
              sm="4"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="kontrakHingga"
                    label="Input tanggal"
                    prepend-icon="mdi-calendar"
                    readonly
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="kontrakHingga"
                  color="purple"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>
            <v-col
              v-if="toggleKontrakHingga === true"
              class="font-common-bold d-flex"
              md="5"
              cols="5"
              sm="5"
            >
              <v-btn
                class="align-self-center"
                elevation="2"
                color="purple"
                small
                v-bind="attrs"
                v-on="on"
                @click="updateTanggalKontrak()"
              >
                Update
              </v-btn>
              <v-tooltip
                bottom
                color="purple"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="align-self-center"
                    outlined
                    x-small
                    fab
                    color="purple"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleKontrakHingga = !toggleKontrakHingga"
                  >
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Batal Edit</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              PBL
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ (detail.addon_info.share_percent_prt || 0) + '%' || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Saldo Dompet
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.addon_info.wallet_saldo || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Status
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.addon_info.status_string || '-' }}
            </v-col>
          </v-row>
        </v-card>

        <!-- Dokumen -->
        <v-card class="px-4 pt-4 pb-8">
          <v-row>
            <v-col class="font-common-black-bold">
              Dokumen
            </v-col>
          </v-row>

          <template v-if="detail.documents.length > 0">
            <v-row
              v-for="(v, i) in detail.documents"
              :key="i"
            >
              <v-col
                class="font-common"
                cols="4"
                sm="4"
              >
                {{ v.name }}
              </v-col>

              <v-col
                class="font-common-bold"
                cols="8"
                sm="8"
              >
                {{ v.file_name + '(' + v.size + ')' }}
                <v-icon
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(v)"
                >
                  fas fa-eye
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-card>

        <!-- Informasi Aset -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Aset
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common pl-8"
              cols="12"
              sm="3"
            >
              Total Aset <span class="font-common-bold ml-2">{{ asetAddOn.total }}</span>
            </v-col>

            <v-col
              class="font-common"
              cols="12"
              sm="3"
            >
              Aset Terverifikasi <span class="font-common-bold ml-2">{{ asetAddOn.proses_verifikasi }}</span>
            </v-col>

            <v-col
              class="font-common"
              cols="12"
              sm="3"
            >
              Aset Aktif <span class="font-common-bold ml-2">{{ asetAddOn.aktif }}</span>
            </v-col>

            <v-col
              class="font-common"
              cols="12"
              sm="3"
            >
              Aset Blokir <span class="font-common-bold ml-2">{{ asetAddOn.non_aktif_travikr }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerInformasiAset"
                :items="informasiAset"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.code="{ item }">
                  <div
                    class="font-common"
                    style="
                      color: #7A1878;
                      cursor: pointer;
                    "
                    @click="toAsetDetail(item.hash)"
                  >
                    {{ item.code }}
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageInformasiAset"
                      :length="pageCountInformasiAset"
                      color="purple"
                      :total-visible="5"
                      @input="onChangePageInformasiAset"
                    />
                    <!-- <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageInformasiAset }} of {{ pageCountInformasiAset || 1 }}
                    </span> -->
                    <span style="font-size: 12px; font-style: italic;">
                      {{ itemCountInformasiAset + ' dari ' + itemTotalInformasiAset + ' ditampilkan' }}
                    </span>
                  </div>
                  <!-- <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountInformasiAset + ' dari ' + itemTotalInformasiAset + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Sub Akun Perusahaan -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Sub Akun Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerSubAkunPerusahaan"
                :items="subAkunPerusahaan"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageSubAkunPerusahaan"
                      :length="pageCountSubAkunPerusahaan"
                      color="purple"
                      @input="onChangePageSubAkunPerusahaan"
                    />
                    <!-- <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageSubAkunPerusahaan }} of {{ pageCountSubAkunPerusahaan || 1 }}
                    </span> -->
                    <span style="font-size: 12px; font-style: italic;">
                      {{ itemCountSubAkunPerusahaan + ' dari ' + itemTotalSubAkunPerusahaan + ' ditampilkan' }}
                    </span>
                  </div>
                  <!-- <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountSubAkunPerusahaan + ' dari ' + itemTotalSubAkunPerusahaan + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Mutasi Dompet -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Mutasi Dompet
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerMutasiDompet"
                :items="mutasiDompet"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.nominal="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      Rp
                    </span>

                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      {{ item.nominal | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageMutasiDompet"
                      :length="pageCountMutasiDompet"
                      color="purple"
                      @input="onChangePageMutasiDompet"
                    />
                    <!-- <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageMutasiDompet }} of {{ pageCountMutasiDompet || 1 }}
                    </span> -->
                    <span style="font-size: 12px; font-style: italic;">
                      {{ itemCountMutasiDompet + ' dari ' + itemTotalMutasiDompet + ' ditampilkan' }}
                    </span>
                  </div>
                  <!-- <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountMutasiDompet + ' dari ' + itemTotalMutasiDompet + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- BLOKIR MITRA -->
        <template v-if="detail.addon_info.status === 1 && partnerCompanyEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                style="
                  background: #EA3E3A;
                  width: 162px;
                  height: 46px;
                "
                @click="dialogBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-white-bold"
                >
                  Blokir Mitra
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- BUKA BLOKIR MITRA -->
        <template v-if="detail.addon_info.status === 2 && partnerCompanyEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                class="elevation-0"
                style="
                  background: #FAFAFA;
                  width: 162px;
                  height: 46px;
                "
                @click="dialogUnBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-bold"
                >
                  Buka Blokir Mitra
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <!-- DIALOG BLOKIR MITRA -->
    <v-dialog
      v-model="dialogBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin memblokir mitra ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              id="password-blokir"
              v-model="password"
              label="Masukkan Password"
              color="purple"
              outlined
              dense
              auto-complete="off"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword = !showPassword)"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(2)"
            >
              <span class="font-common-white-bold">
                Ya, Lanjutkan
              </span>
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG UN-BLOKIR MITRA -->
    <v-dialog
      v-model="dialogUnBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin buka blokir mitra ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              id="password-buka-blokir"
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(1)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogUnBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      detail: {
        company_info: {
          hash: '',
          name: '',
          name_legal: '',
          verified: true,
          bank_name: '',
          bank_owner: '',
          bank_number: '',
          bank_verified: true,
          phone: '',
          address: '',
        },
        board_info: {
          name: '',
          phone: '',
        },
        addon_info: {
          contract_validity_date_nearby: false,
          contract_validity_date: null,
          share_percent_prt: 0,
          wallet_saldo: 0,
          status: 1,
          status_string: 'Aktif',
        },
        documents: [],
      },

      riwayat: {

      },

      // Informasi Aset:
      headerInformasiAset: [
        { text: 'Kode', align: 'start', value: 'code', sortable: false },
        { text: 'Nama Media', value: 'name' },
        { text: 'Lokasi', value: 'add_kot' },
        { text: 'Status', value: 'status_string' },
      ],
      informasiAset: [],

      pageInformasiAset: 1,
      pageCountInformasiAset: 0,
      itemCountInformasiAset: 0,
      itemTotalInformasiAset: 0,

      asetAddOn: {
        total: 0,
        aktif: 0,
        tidak_aktif: 0,
        proses_verifikasi: 0,
        verifikasi_gagal: 0,
        draft: 0,
        non_aktif_travikr: 0,
      },

      // Sub Akun Perusahaan:
      headerSubAkunPerusahaan: [
        { text: 'Nama', align: 'start', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'No.Hp', value: 'phone', sortable: false },
        { text: 'Peran', value: 'pos_nama' },
        { text: 'Status', value: 'status_string' },
      ],
      subAkunPerusahaan: [],

      pageSubAkunPerusahaan: 1,
      pageCountSubAkunPerusahaan: 0,
      itemCountSubAkunPerusahaan: 0,
      itemTotalSubAkunPerusahaan: 0,

      // Mutasi :
      headerMutasiDompet: [
        { text: 'Jenis Transaksi', align: 'start', value: 'type', sortable: false },
        { text: 'Tanggal Transaksi', value: 'tanggal', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Keterangan', value: 'description', sortable: false },
      ],
      mutasiDompet: [],

      pageMutasiDompet: 1,
      pageCountMutasiDompet: 0,
      itemCountMutasiDompet: 0,
      itemTotalMutasiDompet: 0,

      dialogBlokirPenyewa: false,
      dialogUnBlokirPenyewa: false,
      password: '',

      showPassword: false,

      toggleKontrakHingga: false,
      kontrakHingga: (new Date(Date.now())).toISOString().split('T')[0],
      menu2: false,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      newsLetter () {
        let r = ''
        this.detail.newsletter_subscribe ? r = 'Langganan' : r = 'Tidak Berlangganan'

        return r
      },

      partnerCompanyEdit () {
        let v = false
        if (this.userMenus.includes('PARTNER_COMPANY_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
      this.getInformasiAset(1)
      this.getSubAkunPerusahaan(1)
      this.getMutasi(1)
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
            console.log(this.detail)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getInformasiAset (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/asset', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.asetAddOn = res.data.data.addon
            vm.informasiAset = res.data.data.list
            // pagination:
            vm.pageCountInformasiAset = res.data.data.pagination.page_total
            vm.itemCountInformasiAset = res.data.data.pagination.item_count
            vm.itemTotalInformasiAset = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getSubAkunPerusahaan (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/subaccount', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.subAkunPerusahaan = res.data.data.list
            // pagination:
            vm.pageSubAkunPerusahaan = res.data.data.pagination.page_total
            vm.itemCountSubAkunPerusahaan = res.data.data.pagination.item_count
            vm.itemTotalSubAkunPerusahaan = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getMutasi (page) {
        const vm = this

        const requestBody = {
          page: page,
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail/wallet', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.mutasiDompet = res.data.data.list
            // pagination:
            vm.pageCountMutasiDompet = res.data.data.pagination.page_total
            vm.itemCountMutasiDompet = res.data.data.pagination.item_count
            vm.itemTotalMutasiDompet = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      blokirPenyewa (status) {
        if (this.password === '') {
          if (status === 2) {
            setTimeout(() => {
              document.getElementById('password-blokir').focus()
            }, 100)
          }
          if (status === 1) {
            setTimeout(() => {
              document.getElementById('password-buka-blokir').focus()
            }, 100)
          }

          return
        }

        const requestBody = {
          prt_comp_hash: this.$route.params.id,
          pwd: this.password,
          new_status: status,
        }

        axios.post('/v2/t/partner/company/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogBlokirPenyewa = false
            this.dialogUnBlokirPenyewa = false
            this.password = ''
            this.$toast.success('Berhasil mengubah status mitra')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toAsetDetail (hash) {
        // console.log(hash)
        this.$router.push({ name: 'AsetDetail', params: { id: hash } })
      },

      onChangePageMutasiDompet (e) {
        this.getMutasi(e)
      },

      onChangePageInformasiAset (e) {
        this.getInformasiAset(e)
      },

      onChangePageSubAkunPerusahaan (e) {
        this.getSubAkunPerusahaan(e)
      },

      navBack () {
        this.$router.push({ name: 'Mitra' })
      },

      downloadAttachment (data) {
        const type = data.file_type.toUpperCase()
        if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
          const modal = document.getElementById('myModal')
          const modalImg = document.getElementById('img01')
          modal.style.display = 'block'
          if (data.url) modalImg.src = data.url
          if (data.path) modalImg.src = data.path
          if (data.file_path_view_only) modalImg.src = data.file_path_view_only
          const span = document.getElementsByClassName('close')[0]
          span.onclick = function () {
            modal.style.display = 'none'
          }
        } else if (type === 'PDF') {
          window.open(data.url, '_blank')
        } else {
          window.open(data.url_download, '_blank')
        }
      },

      updateTanggalKontrak () {
        const requestBody = {
          prt_com_hash: this.$route.params.id,
          contract_date: this.kontrakHingga,
        }

        axios.post('/v2/t/partner/company/update', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Update ' + res.statusText)
            setTimeout(function () {
              window.location.reload(1)
            }, 1500)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-dura-------tion: 0.6s;
}
.v-picker.v-card{
  margin-top: 0;
  margin-bottom: 0;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
